export const TEMPLATE_FIELDS_TO_DISABLE = [
  'applicationDate',
  'branch',
  'jobLocation',
  'responsiblePerson',
  'responsibleEnterpriseManager',
  'responsibleCustomer',
  'hiddenFromEnterpriseManagers',
  'hiddenFromCustomers',
  'hiddenFromUsers',
  'customersInChargeOfJob'
];
