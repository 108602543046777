export enum QUIZ_MODULES {
  INHOUSE_QUESTION = 'inhouseQuestion',
  KNOCKOUT_QUESTIONS = 'knockoutQuestions',
  BUSINESS_QUESTIONS = 'businessQuestions',
  VIDEO_QUESTIONS = 'videoQuestions'
}

export const QUIZ_MODULES_ARRAY = [
  QUIZ_MODULES.INHOUSE_QUESTION,
  QUIZ_MODULES.KNOCKOUT_QUESTIONS,
  QUIZ_MODULES.BUSINESS_QUESTIONS,
  QUIZ_MODULES.VIDEO_QUESTIONS
];
