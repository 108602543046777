import {
  initializeFacebookAndInstagramJobForm
} from './job-forms';
import {
  FACEBOOK_AND_INSTAGRAM_FIELDS_TO_DISABLE,
} from './platform-specific-fields';
import { FormGroup } from '@angular/forms';

export enum JobPlatform {
  higher = 0,
  facebookAndInstagram = 1
}

export interface JobPlatformPublish {
  name: string;
  jobFormName: string;
  templateFormName: string;
  initForm: () => FormGroup;
  fieldsToDisable: string[];
}

export type JobPlatformsPublish = Map<JobPlatform, JobPlatformPublish>;

export const JOB_PLATFORMS_PUBLISH: JobPlatformsPublish = new Map([
  [
    JobPlatform.facebookAndInstagram,
    {
      name: 'facebookAndInstagram',
      jobFormName: 'facebookAndInstagramJob',
      templateFormName: 'facebookAndInstagramJobTemplate',
      initForm: initializeFacebookAndInstagramJobForm,
      fieldsToDisable: FACEBOOK_AND_INSTAGRAM_FIELDS_TO_DISABLE
    }
  ],
]);
