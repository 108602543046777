import { facebookAndInstagramJob, PublishingPlatforms } from './publishing-platform.interface';
import ICompany from './company.interface';
import { TranslationKeys, TranslationObject } from './translation-object.interface';
import { Branch } from './branch.interface';
import { User, UserCommunicator } from './user.interface';
import { UniversalOption } from './universal-option.interface';
import { QUIZ_MODULES } from '../resources/quiz-modules';
import { Trigger } from './trigger.interface';
import { IHiringStatus } from './hiring-status.interface';

interface IBaseJob {
  applicationDate: string;
  applicationHiringStatusTriggers: Trigger[];
  archived: boolean;
  branch: Branch | number;
  businessQuestions: Quiz;
  company: ICompany;
  contactDetail: boolean;
  creatorEnterpriseManager: UserCommunicator;
  creatorCustomer: UserCommunicator;
  guid: string;
  hiddenFromCustomers: Partial<User>[] | number[];
  hiddenFromEnterpriseManagers: Partial<User>[] | number[];
  hiddenFromUsers?: Partial<User>[];
  id: number;
  applicationHiringStatusesWhoseTriggersAreIgnored: IHiringStatus[] | number[];
  isJobTemplateDraft: boolean;
  introSms: TranslationObject;
  jobDescription: string;
  jobDescriptionTemplate: string;
  jobLocation: JobLocation | number;
  jobPlatforms: PublishingPlatforms | number[];
  jobTitle: string;
  knockoutQuestions: Quiz;
  language: TranslationKeys;
  notificationEnabled: boolean;
  orderOfQuestionsModules: QUIZ_MODULES[];
  picture: string;
  publishOnHigher: boolean;
  publishedJobPlatforms: PublishingPlatforms;
  reminderSms: string;
  responsibleCustomer: Partial<User> | number;
  responsibleEnterpriseManager: Partial<User> | number;
  sendReminderSms: boolean;
  shortGuid: string;
  shortJobDescription: string;
  shortJobDescriptionTemplate: string;
  shortJobUrl: string;
  status: string;
  introMainMessage: string;
  introMessage: string;
  thankYouMainMessage: string;
  thankYouMessage: string;
  video: string;
  videoQuestions: VideoQuestion;
  boundedJobIds: number[];
  disableSurveysForEdit: boolean;
  facebookAndInstagramJob?: facebookAndInstagramJob;
  streamVideoAnswers?: boolean;
  linkVideoAnswers?: boolean;
  uploadVideoAnswers?: boolean;
}

export interface IJob extends IBaseJob {
  branch: Branch;
  hiddenFromCustomers: Partial<User>[];
  hiddenFromEnterpriseManagers: Partial<User>[];
  applicationHiringStatusesWhoseTriggersAreIgnored: IHiringStatus[];
  jobLocation: JobLocation;
  jobPlatforms: PublishingPlatforms;
  responsibleCustomer: Partial<User>;
  responsibleEnterpriseManager: Partial<User>;
  contentButton: string;
  customersInChargeOfJob: Partial<User>[];
}

export interface IJobPayload extends IBaseJob {
  branch: number;
  hiddenFromCustomers: number[];
  hiddenFromEnterpriseManagers: number[];
  hiddenFromUsers: Partial<User>[];
  applicationHiringStatusesWhoseTriggersAreIgnored: number[];
  jobLocation: number;
  jobPlatforms: number[];
  responsibleCustomer: number;
  responsibleEnterpriseManager: number;
  responsiblePerson: Partial<User>
  customersInChargeOfJob: number[];
  streamVideoAnswers?: boolean;
  linkVideoAnswers?: boolean;
  uploadVideoAnswers?: boolean;
}

export interface Quiz {
  minPercentageForPass?: number;
  passAllCandidates?: boolean;
  questions: Question[];
  rejectionMessage?: string;
  rejectionMessageMain?: string;
}

export interface InhouseQuiz {
  passAllInhouseCandidates?: boolean;
  question: Question;
}

export interface Question {
  answers: Answer[];
  questionImportance?: number;
  text?: string;
  image?: string;
  video?: string;
  questionType?: TypeOfSkillsQuestion;
  questionScoreDividerIsMaxNumberOfAnswersCandidateCanChoose?: boolean;
  maxNumberOfAnswersCandidateCanChoose?: number;
}

export interface Answer {
  text: string;
  correct?: boolean;
  desirability?: number;
  isTagVisible?: boolean;
  tag?: string;
  followUpQuestion?: Question;
}

export interface VideoQuestion {
  questions?: string[];
  q1?: string;
  q2?: string;
  q3?: string;
  video: string;
  videoMessage: string;
  videoMainMessage: string;
  addVideoOptions?: {
    streamVideoAnswers?: boolean;
    linkVideoAnswers?: boolean;
    uploadVideoAnswers?: boolean;
  }
}

export interface ListJob {
  applicationDate: string;
  status: string;
  applications?: ListJobApplication[];
  createdAt: string;
  updatedAt: string;
  id: number;
  jobTitle: string;
  jobUrl: string;
  totalApplications?: number;
  unhandledApplications?: number;
  newApplications?: number;
  archived: boolean;
  guid: string;
  jobLocationId: number;
  jobLocationName: TranslationObject;
  responsibleCustomerName: string | null;
  responsibleCustomerId: string | null;
  responsibleEnterpriseManagerName: string | null;
  responsibleEnterpriseManagerId: string | null;
  checked?: boolean;
  category: number;
}

export interface JobLocation {
  id: number;
  name: TranslationObject;
  municipality?: TranslationObject;
  region?: TranslationObject;
}

export interface ResponsiblePerson {
  createdAt: string;
  updatedAt: string;
  name: string;
}

export interface JobStatistics {
  jobsCount: number;
  activeJobs: number;
  inactiveJobs: number;
  averageApplicationsPerJob: number;
}

export interface ListJobApplication {
  id: number;
  hiringStatus: BaseHiringStatus;
  createdAt: string;
  updatedAt: string;
}

export interface BaseHiringStatus {
  id?: number;
  name: string;
  nameModified: boolean;
}

export interface JobInfo {
  applicationDate?: string;
  branch?: number;
  contactDetail?: boolean;
  guid?: string;
  excludedJobTriggersHiringStatusIds: number[],
  isTemplate?: boolean;
  jobLocation?: number;
  jobTitle: string;
  language?: TranslationKeys;
  notificationEnabled?: boolean;
  responsiblePerson?: Partial<User>;
  status?: string;
  triggers: Trigger[];
  responsibleCustomer?: Partial<User>;
  responsibleEnterpriseManager?: Partial<User>;
  hiddenFromCustomers?: Partial<User>[];
  hiddenFromEnterpriseManagers?: Partial<User>[];
  hiddenFromUsers?: Partial<User>[];
  boundedJobIds: number[];
  id?: number;
  customersInChargeOfJob: number[];
}

export interface JobDetails {
  picture?: string;
  video?: string;
  jobDescription: string;
  jobDescriptionTemplate?: string;
  shortJobDescription: string;
  shortJobDescriptionTemplate?: string;
  reminderSms: string;
  thankYouMessage: string;
  thankYouMainMessage: string;
  introMessage: string;
  introMainMessage: string;
  sendReminderSms: boolean;
}
export interface QuizModules {
  orderOfQuestionsModules: QUIZ_MODULES[];
  inhouseQuestion?: InhouseQuiz;
  knockoutQuestions?: Quiz;
  businessQuestions?: Quiz;
  videoQuestions?: VideoQuestion;
}

export interface ListTemplate {
  id: number;
  jobTitle: string;
}
export interface ActiveJob {
  id: number;
  type: string;
  title?: string;
  guid?: string;
}

export interface ApplicationJob {
  createdAt: string;
  company: ICompany;
  jobLocation?: JobLocation;
  responsiblePerson?: ResponsiblePerson;
  id: number;
  jobTitle: string;
  language: string;
  branch?: Branch;
  archived?: boolean;
  hiddenFromUsers: Partial<User>[];
  hiddenFromCustomers?: Partial<User>[];
  hiddenFromEnterpriseManagers?: Partial<User>[];
}

export interface JobIds {
  jobIds: string[] | null;
  universalJobId?: string | null;
}

export enum TypeOfJob {
  universalJob = 'universalJob',
  job = 'job'
}

export enum TypeOfSkillsQuestion {
  singleAnswerQuestion = 'singleAnswerQuestion',
  multipleAnswersQuestion = 'multipleAnswersQuestion',
  questionWithFollowUpQuestions = 'questionWithFollowUpQuestions',
  freeAnswerQuestion = 'freeAnswerQuestion'
}

export enum JobStatus {
  active = 'active',
  inactive = 'inactive',
  archive = 'archive'
}
