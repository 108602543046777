interface InhouseQuestionContent {
  [key: string]: {
    text: string;
    yes: string;
    no: string
  }
}

export const INHOUSE_QUESTION_CONTENT: InhouseQuestionContent = {
  en: {
    text: 'Have you ever cast for this role before?',
    yes: 'Yes',
    no: 'No'
  },
  sv: {
    text: 'Har du någonsin castat för denna roll tidigare?',
    yes: 'Ja',
    no: 'Nej'
  }
};
