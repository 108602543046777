import { Injectable, signal } from '@angular/core';
import { Countries } from '../resources/countries';
import { TranslationKeys } from '../model/translation-object.interface';
import { Company } from '../classes/organization.class';

@Injectable({ providedIn: 'root' })
export class SetupService {

  private _companyId: number;
  private _currentCompany: Company;
  private _logo = signal<string>('');
  logo = this._logo.asReadonly();

  constructor() {}

  set setLogo(val: string) {
    this._logo.set(val);
  }

  get companyId(): number {
    return this._companyId;
  }

  set companyId(id: number) {
    this._companyId = id;
  }

  get currentCompany(): Company {
    return this._currentCompany;
  }

  set currentCompany(company: Company) {
    this._currentCompany = company;
  }

  get countryId(): Countries {
    return this._currentCompany.location.country.id;
  }

  get companyGuid(): string {
    return this._currentCompany?.guid;
  }

  get companyLanguage(): TranslationKeys {
    return this._currentCompany.language;
  }

  get companyName(): string {
    return this._currentCompany?.name;
  }

  get companyPossibleLanguages(): TranslationKeys[] {
    const languages: TranslationKeys[] = [TranslationKeys.EN];

    if (this.companyLanguage !== TranslationKeys.EN) {
      languages.push(this.companyLanguage);
    }

    return languages;
  }

  get areInterviewsEnabled(): boolean {
    return this._currentCompany?.isAllowedVideoInterview;
  }

  get areAgreementsEnabled(): boolean {
    return this._currentCompany?.isAllowedAgreementsFeature;
  }
}
